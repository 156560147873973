import axios from "axios";
// import { baseURL } from "../config";

axios.defaults.withCredentials = true;

const config = {
    baseURL: '/api/v1',
    withCredentials: true,
    // timeout: 3000
}
const callApi = axios.create(config)
// callApi.interceptors.request.use(function (config) {
//     config.headers = {
//         'contect-type': 'text/plain',
//     }
//     return config;
// })

export default callApi;