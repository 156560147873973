import { useLayoutEffect, useState } from 'react';
import callApi from '../callApi/callApi';

const useAuth = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState();
    const [role, setRole] = useState('');

    const fetchUser = async () => {
        try {
            const { data } = await callApi.get('/me');

         
 
            setAuthenticated(data.success)
            setUser(data?.user);
            setRole(data?.user?.role);
            setLoading(false);
            if (data?.success === false) {
                setLoading(false);
                setAuthenticated(false)
                setUser(null)
            }

        } catch (error) {
            setLoading(false);
            setAuthenticated(false)
            setError(error?.response?.data);
            setUser(null)
        }
    }

    useLayoutEffect(() => {
        fetchUser()
    }, [])

    return { loading, error, authenticated, user, role };
};

export default useAuth;