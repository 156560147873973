

export const mode = 'DEVELOPMENT'; // it will be DEVELOPMENT or PRODUCTION
export let baseURL;

if (mode === 'PRODUCTION') {
    baseURL = 'https://server.dreamland-bd.com/api/v1'
} else if (mode === 'DEVELOPMENT') {
    baseURL = 'http://localhost:8080/api/v1'
}


export const currency = "$";

export const taxPercent = .15;

export const ADMIN_ROLE = "dreamlandbd-admin"

export const MODERATOR_ROLE = "dreamlandbd-moderator";

export const USER_ROLE = "user";
