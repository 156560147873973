import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthGruad = ({ auth, loading }) => {


    const location = useLocation();
    const path = location.pathname ? location.pathname : '/';

    return !loading && ((auth) ? <Outlet /> : <Navigate to={`/signin?redirect=${path}`} />)


};

export default AuthGruad;