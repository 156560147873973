import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const AccountDetailsSkeleton = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} xl={12}>
                    <Skeleton
                        sx={{ bgcolor: "grey.700", borderRadius: 1, marginTop: 1 }}
                        variant="rectangular"
                        width={"100%"}
                        height={50}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <Skeleton
                        sx={{ bgcolor: "grey.700", borderRadius: 1, marginTop: 1 }}
                        variant="rectangular"
                        width={"100%"}
                        height={200}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <Skeleton
                        sx={{ bgcolor: "grey.700", borderRadius: 1, marginTop: 1 }}
                        variant="rectangular"
                        width={"100%"}
                        height={200}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <Skeleton
                        sx={{ bgcolor: "grey.700", borderRadius: 1, marginTop: 1 }}
                        variant="rectangular"
                        width={"100%"}
                        height={60}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <Skeleton
                        sx={{ bgcolor: "grey.700", borderRadius: 1, marginTop: 1 }}
                        variant="rectangular"
                        width={"100%"}
                        height={60}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <Skeleton
                        sx={{ bgcolor: "grey.700", borderRadius: 1, marginTop: 1 }}
                        variant="rectangular"
                        width={"100%"}
                        height={60}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccountDetailsSkeleton;