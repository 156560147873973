import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AdminAuthGruad = ({ auth, role, admin, moderator }) => {

	
	const location = useLocation();
	const path = location.pathname ? location.pathname : '/';

	if (admin && moderator) {
		return auth ? (
			(admin === role || moderator === role) ? (
				<Outlet />
			) : (
				<Navigate to="/denied" />
			)
		) : (
			<Navigate to={`/signin?redirect=${path}`} />
		)
	} else if (admin) {
		return auth ? (
			admin === role ? (
				<Outlet />
			) : (
				<Navigate to="/denied" />
			)
		) : (
			<Navigate to={`/signin?redirect=${path}`} />
		)
	}
};

export default AdminAuthGruad;