import { toast } from "react-toastify";
import callApi from "../callApi/callApi";

// CREATE A TAGLINES --ADMIN
export const createTagLine = async (tData) => {

  try {

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }

    const { data } = await callApi.post(`/admin/tag/create`, tData, config);

    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// GET ALL TAGLINES --ADMIN
export const getAllTagline = async () => {

  try {
    const { data } = await callApi.get(`/admin/tag/all`);

    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};


// GET LATEST TAGLINES
export const getLatestTags = async () => {

  try {
    const { data } = await callApi.get(`/tag/latest`);

    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};


// DELETE TAGLINES --ADMIN
export const deleteTagline = async (id, func) => {

  try {
    const { data } = await callApi.delete(`/admin/tag/${id}`);

    await func();
    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// HEADER SECTION //

// CREATE PRODUCT
export const createHeader = async (headerData, func) => {

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };

    const { data } = await callApi.post(`/admin/header/create`, headerData, config);

    if (data.success === true) {
      toast.success("Header Created Successfully!")
      await func();
    } 

    return data;

  } catch (error) {

    toast.error(error?.response?.data?.message);
  }
}



// GET LATEST HEADER
export const getLatestHeader = async () => {

  try {
    const { data } = await callApi.get(`/header/latest`);

    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// GET LATEST HEADER
export const getAllHeader = async () => {

  try {
    const { data } = await callApi.get(`/header/all`);

    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// UPDATE HEADER
export const updateHeader = async (id, headerData, func) => {


  try {
    const config = { headers: { 'Content-Type': 'application/json' } };

    const { data } = await callApi.put(`/admin/header/${id}`, headerData, config);

    return data;

  } catch (error) {

    toast.error(error?.response?.data?.message);
  }
}

// DELETE HEADER
export const deleteHeader = async (id, func) => {


  try {
    const config = { headers: { 'Content-Type': 'application/json' } };

    const { data } = await callApi.delete(`/admin/header/${id}`, config);

    func()
    return data;

  } catch (error) {

    toast.error(error?.response?.data?.message);
  }
};


// MENU ACTIONS 
export const createMenu = async (d, func) => {

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };

    const {data} = await callApi.post(`/admin/menu/create`,d, config);
 
    return data;
  } catch (error) {

    toast.error(error?.response?.data?.message);
  }
}



// MENU ACTIONS 
export const getAllMenu = async ( ) => {

  try { 

    const {data} = await callApi.get(`/menu/all`);
 
    return data;
  } catch (error) {

    toast.error(error?.response?.data?.message);
  }
}

// DELETE HEADER
export const deleteMenu = async (id, func) => {

  try {

    const { data } = await callApi.delete(`/admin/menu/${id}`);

    func()
    return data;
  } catch (error) {

    toast.error(error?.response?.data?.message);
  }
};