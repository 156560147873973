
import { Fragment, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';   
import { ADMIN_ROLE, MODERATOR_ROLE } from '../config';
import useAuth from '../hooks/useAuth';  
import AdminAuthGruad from './AdminAuthGruad';
import AuthGruad from './AuthGruad';
import HomePageLoading from '../loadingPages/HomePageLoading';
import AddMenu from '../Admin/layouts/homepage/AddMenu';
import UserDetails from '../Admin/pages/UserDetails';
import Navigation from '../components/common/Navbar/Navigation';
//
const Home = lazy(() => import('../pages/Home'));
const Products = lazy(() => import('../components/Products/Products'));
const ProductDetails = lazy(() => import('../components/ProductDetails/ProductDetails'));
const Contact = lazy(() => import('../pages/Contact'));
const SignUp = lazy(() => import('../components/SignUp/SignUp'));
const SignIn = lazy(() => import('../components/SignIn/SignIn'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Denied = lazy(() => import('../pages/Denied')); 

// USER COMPONENTS
const Cart = lazy(() => import('../components/Cart/Cart'));
const Account = lazy(() => import('../components/User/Account/Account'));
const ForgotPassword = lazy(() => import('../components/User/ForgotPassword/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('../components/User/ForgotPassword/ForgotPasswordSuccess'));
const ResetPassword = lazy(() => import('../components/User/ResetPassword/ResetPassword'));
const Shipping = lazy(() => import('../components/CheckoutProcess/Shipping'));
const OrderDetails = lazy(() => import('../components/CheckoutProcess/OrderDetails'));
const MyOrderDetails = lazy(() => import('../components/User/MyOrderDetails/MyOrderDetails'))

// ADMIN DASHBOARD COMPONENTS
const DashboardLayout = lazy(() => import('../Admin/layouts/dashboard'));
const DashboardApp = lazy(() => import('../Admin/pages/DashboardApp'));
const ReviewList = lazy(() => import('../Admin/pages/ReviewList'));
const HomePage = lazy(() => import('../Admin/pages/HomePage'))

// Product components
const AddProduct = lazy(() => import('../Admin/pages/AddProduct'));
const ProductList = lazy(() => import('../Admin/pages/ProductList'));
const UpdateProduct = lazy(() => import('../Admin/components/UpdateProduct'));
const AdminProducts = lazy(() => import('../Admin/pages/Products'));
// ORDER COMPONENTS
const AdminOrderList = lazy(() => import('../Admin/pages/AdminOrderList'));
const AdminOrderDetails = lazy(() => import('../Admin/pages/AdminOrderDetails'));
const AdminList = lazy(() => import('../Admin/pages/AdminList'));
const UserList = lazy(() => import('../Admin/pages/UserList'));
const AddHeader = lazy(() => import('../Admin/layouts/homepage/AddHeader'));

// HOME PAGE COMPONENTS
const ManageHeader = lazy(() => import('../Admin/layouts/homepage/ManageHeader'));
const ManageMenu = lazy(() => import('../Admin/layouts/homepage/ManageMenu'));
const ManageTagLine = lazy(() => import('../Admin/layouts/homepage/ManageTagLine'));
const Category = lazy(() => import('../Admin/pages/Category')); 

const AppRoutes = () => {

    const { authenticated, role, loading } = useAuth();


    return (
        <Fragment>
            <Suspense fallback={<HomePageLoading />}>

                {/* <Navbar /> */}
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="products" element={<Products />} />
                    <Route path="/products/:keyword" element={<Products />} />
                    <Route path="/product/:id" element={<ProductDetails />} />
                    <Route path="signin" element={<SignIn auth={authenticated} />} />
                    <Route path="signup" element={<SignUp auth={authenticated} />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="/cart" element={<Cart />} />

                    <Route path="/nav1" element={<Navigation />} />
                    <Route path="/nav2" element={<Navigation />} />
                    <Route path="/nav3" element={<Navigation />} />
                    <Route path="/nav" element={<Navigation />} />
                    <Route path="/accoun" element={<Navigation />} />


                    <Route element={<AuthGruad auth={authenticated} loading={loading} />}>
                        <Route path="account" element={<Account />} />

                        <Route path="/order/shipping" element={<Shipping />} />
                        <Route path="/order/details" element={<OrderDetails />} />
                     
                        <Route path="/order/:id" element={<MyOrderDetails />} />

                        <Route element={<AdminAuthGruad auth={authenticated} loading={loading} admin={ADMIN_ROLE} moderator={MODERATOR_ROLE} role={role} />}>
                            <Route path="/admin" element={<DashboardLayout />}>

                                <Route path="dashboard" element={<DashboardApp />} />

                                <Route path="users" element={<UserList />} />

                                <Route element={<AdminAuthGruad auth={authenticated} loading={loading} admin={ADMIN_ROLE} role={role} />}>

                                    <Route path="admin" element={<AdminList />} />

                                </Route>


                                <Route path="products" element={<ProductList />} />

                                <Route path="product/:id" element={<UpdateProduct />} />

                                <Route path="product/add" element={<AddProduct />} />


                                <Route path="orders" element={<AdminOrderList />} />

                                <Route path="order/:id" element={<AdminOrderDetails />} />

                                <Route exact path="order/delivered" element={<AdminProducts />} />

                                <Route exact path="order/track" element={<AdminProducts />} />

                                <Route path="reviews" element={<ReviewList />} />

                                <Route path="analysis" element={<DashboardApp />} />

                                <Route path="homepage" element={<HomePage />} />

                                <Route path="tagline" element={<ManageTagLine />} />

                                <Route path="header" element={<ManageHeader />} />

                                <Route path="header/add" element={<AddHeader />} />

                                <Route path="menu" element={<ManageMenu />} />

                                <Route path="menu/add" element={<AddMenu />} />

                                <Route path="category" element={<Category />} />

                                <Route path="user/:id" element={<UserDetails />} />

                                <Route path="user/edit/:id" element={<UserDetails />} />

                            </Route>
                        </Route>

                    </Route>

                    <Route path="/password/forgot" element={<ForgotPassword />} />
                    <Route path="/password/forgot/success" element={<ForgotPasswordSuccess />} />
                    <Route path="/password/reset/:token" element={<ResetPassword />} />

                    <Route path="/denied" element={<Denied />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                {/* <Footer /> */}
            </Suspense>
        </Fragment>
    );
};

export default AppRoutes;