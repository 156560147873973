import React from 'react';
import { NavLink } from 'react-router-dom';
import Iconify from '../../../Admin/components/Iconify';
import './Navigation.scss';

const Navigation = () => {
    return (
        <div>
            <div className="navigation">
                <ul>
                    <li>
                        <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'class')}>
                            <span>
                                <Iconify className="icon" icon="bx:home-alt-2" />
                            </span>
                            <span className="text">Home</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/categories" className={({ isActive }) => (isActive ? 'active' : 'class')}>
                            <span>
                                <Iconify className="icon" icon="bx:category" />
                            </span>
                            <span className="text">Category</span>
                        </NavLink>
                    </li> */}
                    <li>
                        <NavLink to="/products">
                            <span>
                                <Iconify className="icon" icon="carbon:product" />
                            </span>
                            <span className="text">Products</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/cart">
                            <span>
                                <Iconify className="icon" icon="bi:bag" />
                            </span>
                            <span className="text">Cart</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/account?tab=profile">
                            <span>
                                <Iconify className="icon" icon="ant-design:user-outlined" />
                            </span>
                            <span className="text">Account</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navigation;