import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createMenu } from '../../../actions/homeAction';
import Page from '../../components/Page';


const AddMenu = () => {

    const navigate = useNavigate();

    const [chiledCount, setChiledCount] = useState(0);

    const [childMenu, setChilMenu] = useState({});

    const [childArray, setChilArray] = useState([]);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();


    const { isLoading, mutateAsync } = useMutation(createMenu, {});

    const childrenArray = [...new Set(childArray)];

    const onSubmit = async menuD => {

        const menuData = {
            ...menuD,
            children: childrenArray
        }
        const data = await mutateAsync(menuData, reset);

        if (data?.success) {
            toast("Menu Created Successfully!");
            navigate('/admin/menu');
            reset();
        }

    };

    const handleChangeData = async (e) => {

        setChilMenu({ ...childMenu, [e.target.name]: e.target.value });

    }

    const handlePushData = async (e) => {
        e.preventDefault();
        // setChiledCount(p => p + 1);

        setChilArray(pre => [...pre, childMenu]);

    }


    return (
        <Page title="Dashboard: Add Menu">
            <Container>
                <Card sx={{ p: 2 }}>
                    <IconButton color="error" onClick={() => navigate(-1)}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Stack direction="row" alignItems="center" justifyContent="center" mb={3}>

                        <Typography variant="h4" gutterBottom>
                            Create Menu
                        </Typography>
                    </Stack>
                    <div className="add-product-form">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    autoComplete="title"
                                    type="text"
                                    label="Menu Title"
                                    {...register("title", { required: true })}
                                    error={Boolean(errors?.title)}
                                    helperText={errors?.title && "Menu Title is Required!"}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="path"
                                    type="text"
                                    label="Menu Path"
                                    {...register("path", { required: true, })}
                                    error={Boolean(errors?.path)}
                                    helperText={errors?.path && "Menu Path is Required!"}
                                />
                            </Stack>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} p={3}>
                                <Box sx={{ width: '90%' }}>
                                    <Typography>{childrenArray && "Children Menu List"}</Typography>
                                    {
                                        childrenArray && childrenArray?.map((i, d) => (
                                            <div>
                                                <details>
                                                    <summary>
                                                        Children:  {d + 1}
                                                    </summary>
                                                    <ul>
                                                        <li>Title: {i?.title}</li>
                                                        <li>Path: {i?.path}</li>
                                                    </ul>
                                                </details>
                                            </div>
                                        ))
                                    }
                                    {
                                        [...new Array(chiledCount)].map((f, index) => (
                                            <form style={{ marginTop: '1rem' }}>
                                                <Stack key={index} spacing={3}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        autoComplete="title"
                                                        type="text"
                                                        label="Child Title"
                                                        name="title"
                                                        onChange={handleChangeData} 
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        autoComplete="path"
                                                        type="text"
                                                        label="Child Path"
                                                        name="path"
                                                        defaultValue="#"
                                                        onChange={handleChangeData} 
                                                    />
                                                </Stack>
                                                <LoadingButton onClick={handlePushData} sx={{ marginTop: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
                                                    Submit
                                                </LoadingButton>
                                                <LoadingButton onClick={() => setChiledCount(p => p + 1)} sx={{ marginTop: 2 }} size="small" variant="contained" loading={false}>
                                                    Add
                                                </LoadingButton>
                                                <LoadingButton onClick={() => setChiledCount(p => p - 1)} sx={{ marginTop: 2, marginLeft: 2 }} size="small" color="error" variant="contained" loading={false}>
                                                    Cancel
                                                </LoadingButton>
                                            </form>))
                                    }
                                </Box>

                                <Box sx={{ width: '100%' }} p={0}>
                                    {
                                        chiledCount < 1 &&
                                        <Button sx={{ m: 2 }} onClick={() => setChiledCount(p => p + 1)} variant="outlined" startIcon={<AddIcon />}>
                                            Add Child Menu
                                        </Button>
                                    }
                                </Box>
                            </Box>


                            <LoadingButton sx={{ marginTop: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
                                Submit
                            </LoadingButton>
                        </form>
                    </div>

                </Card>
            </Container>
        </Page>
    );
};

export default AddMenu;