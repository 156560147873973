import React, { Fragment } from 'react';
import './module.homePageLoading.css';

const HomePageLoading = () => {
    return (
        <Fragment>
            {/* <div className="home-page-loader">
                <div className="spinner-box">
                    <div className="circle-border">
                        <div className="circle-core"></div>
                    </div>
                    <em>Loading ...</em>
                </div>
            </div> */}
            <div className="load">
                <hr /><hr /><hr /><hr />
            </div>
        </Fragment>
    );
};

export default HomePageLoading;