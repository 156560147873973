import { Avatar, Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getUserDetails } from '../../actions/userAction';
import { currency } from '../../config';
import AccountDetailsSkeleton from '../../skeletons/AccountDetailsSkeleton';
import Page from '../components/Page';
import './style.css';

const UserDetails = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { data, isLoading } = useQuery(id, () => getUserDetails(id));

    const oData = [];
    
    data?.orderItems?.forEach((item)=> {
        item?.forEach(order => oData.push(order))
    });
    const orderItems = [...new Set(oData)];

    return (
        <Page title="User Details">
            {
                isLoading ? <AccountDetailsSkeleton /> :
                    <Box>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        {(data?.name)?.slice(0, 1)}
                                    </Avatar>
                                }
                                title={data?.name}
                                subheader={data?._id}
                            />
                            <CardContent>
                                <Box className="user-account-info">
                                    <Box className="account-info">
                                        <Typography variant="h6" gutterBottom component="div">Account Info</Typography>
                                        <div>
                                            <p>Name: <b> {data?.name}</b></p>
                                            <p>Email: <b> {data?.email}</b></p>
                                            <p>Phone: <b>{data?.phone}</b></p>
                                            <p>Join Date: <b>{(new Date(data?.accountCreatedAt).toDateString())}</b></p>
                                        </div>
                                    </Box>

                                    <Box className="order-info">
                                        <Typography variant="h6" gutterBottom component="div">Order Info</Typography>
                                        <div>
                                            <p>Total Amount: <b> {currency}{data?.total || 0}</b></p>
                                            <p>Items Price: <b> {currency}{data?.itemsPrice || 0}</b></p>
                                            <p>Tax Charge: <b> {currency}{data?.taxPrice || 0}</b></p>
                                            <p>Shipping Price: <b> {currency}{data?.shippingPrice || 0}</b></p>
                                            <p>Total Order: <b> {data?.orderItems?.length || 0}</b></p>
                                        </div>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography variant="h5" sx={{ p: 2, mt: 2 }} gutterBottom component="div">Order Items</Typography>
                                    <div className="order-item-container">
                                        {
                                             orderItems &&  orderItems.map((item) => (
                                                <div className="" key={item._id}>
                                                    <img src={item.image} alt="" />
                                                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                                                    <span>
                                                        {item.quantity} X  {currency}{item.price} = {" "}
                                                        <b>{currency}{item.quantity * item.price}</b>
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <button className="backBtn" onClick={() => navigate(-1)}>Back</button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
            }

        </Page>
    );
};

export default UserDetails;