import { toast } from "react-toastify"; 
import callApi from "../callApi/callApi";

// LOGIN
export const login = async (loginData) => {
    try { 
        const { data } = await callApi.post(`/login`, loginData, {withCredentials:true});
        toast.success(data?.user?.name + ' login successfully');

        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);

        return error?.response?.data;
    }
};

// REGISTER 
export const registation = async (registerData) => {
 
    try {
        const config = { headers: { "Content-Type": "multipart/form-data" }, withCredentials:true };

        const { data } = await callApi.post(`/register`, registerData, config);
        
        toast.success(data.user.name + ' register successfully')
        return data?.user;
    } catch (error) { 
        toast?.error(error?.response?.data?.message); 
        return error?.response?.data;
    }

}


// LOAD USER
export const loggedUser = async () => {
    try { 
        const config = { headers: { 'Content-Type': 'application/json' }, withCredentials:true };

        const { data } = await callApi.get(`/me`, config)

        return data; 
    } catch (error) {
        return error?.response?.data;
    }
}

// LOGOUT
export const logout = async () => {
    try { 
        const { data } = await callApi.get(`/logout`);
        toast.success('logout successfully');
        // window.location.reload();
        return data;
    } catch (error) {
        toast.error(error.response.data.message);

        return error?.response?.data;
    }
}

// UPDATE PASSWORD
export const updatePassword = async (passwords) => {
    try { 
        const { data } = await callApi.put(`/password/update`, passwords, {withCredentials:true});
       
        if(data?.success) {
           toast("Password Updated Successfully"); 
        }
        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);

        return error?.response?.data;
    }
}


// UPDATE PROFILE
export const updateProfile = async (userData) => {
    try {
        const config = {headers: {'Content-Type': 'application/json'}};

        const { data } = await callApi.put(`/me/update`, userData, config);

        toast.success(data?.message);
     
        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);

        return error?.response?.data;
    }
}


// UPDATE PROFILE
export const updateAddress = async (addressData) => {
    try {
        const address = {
            address: {
                ...addressData
            }  
        }
        const config = {headers: {'Content-Type': 'application/json'}};

        const { data } = await callApi.put(`/me/update`, address, config);

        toast.success(data?.message);
     
        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);

        return error?.response?.data;
    }
}

// FORGOT PASSWORD
export const forgotPassword = async (email) => {
    try {
        const config = {headers: {'Content-Type': 'application/json'}};

        const { data } = await callApi.post(`/password/forgot`, email, config);

        toast.success(data?.message + ` Please Check Your Email!`);
     
        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);
       
        return error?.response.data;
    }

}


// FORGOT PASSWORD
export const resetPassword = async (resetData) => {
 
    try {
        const config = {headers: {'Content-Type': 'application/json'}};

        const { data } = await callApi.put(`/password/reset/${resetData?.token}`, resetData, config);

        toast.success(data?.message);
     
        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);
       
        return error?.response?.data;
    }

}

// GET ALL USERS 
export const getAllUsers = async () => {

    try { 
        const config = { headers: { 'Content-Type': 'application/json' }, withCredentials:true };

        const { data } = await callApi.get(`/admin/users`, config)

        return data; 

    } catch (error) {

        toast.error(error?.response?.data?.message);

        return error?.response?.data; 
    }
}

// GET ALL ADMINS 
export const getAllAdmin = async () => {

    try { 
        const config = { headers: { 'Content-Type': 'application/json' }, withCredentials:true };

        const { data } = await callApi.get(`/admin/admins`, config)

        return data; 

    } catch (error) {
        
        toast.error(error?.response?.data?.message);

        return error?.response?.data; 
    }
}


// MAKE ADMIN
export const makeAdmin = async (id, da, func) => {

    try { 
        const config = { headers: { 'Content-Type': 'application/json' }, withCredentials:true };
   
        const { data } = await callApi.put(`/admin/user/${id}`, da, config);

        await func()
        return data; 
    } catch (error) {
        
        toast.error(error?.response?.data?.message);

        return error?.response?.data; 
    }
}


// GET USER DETAILS
export const getUserDetails = async (id) => {
    try { 
        
        const link1 = `/admin/user/${id}`;
        const link2 = `/admin/order/analysis/user/${id}`;
   
        const { data: data1 } = await callApi.get(link1);
        const { data: data2 } = await callApi.get(link2);

        const data = {
            ...data1?.user, ...data2?.user
        } 

        return data; 
    } catch (error) {
        
        toast.error(error?.response?.data?.message);
        return error?.response?.data; 
    }
}

export const getLoggedOrderDetails = async () => {
    try { 
        const {data} = await callApi.get(`/admin/order/user/me`)

        return data;
    } catch (error) {
        toast.error(error?.response?.data?.message);
        return error?.response?.data; 
    }
}