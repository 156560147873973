import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseOptionChartStyle } from './Admin/components/chart/BaseOptionChart';
import ScrollToTop from './Admin/components/ScrollToTop';
import './classes.scss';
import AppRoutes from './routes/AppRoutes';
import ThemeProvider from './theme';
import TopToScroll from './utils/TopToScroll';


const App = () => {

  if (window.navigator.onLine) {
    console.log('online');
  } else {
    console.log('offline');
  }

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />

        <AppRoutes />

        
        <TopToScroll />
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
