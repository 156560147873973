import React, { useState } from 'react';
import Iconify from '../Admin/components/Iconify';

const TopToScroll = () => {

    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };
    window.addEventListener('scroll', checkScrollTop)

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div>
            <Iconify
                icon="akar-icons:arrow-up"
                className="scrollTop"
                onClick={scrollTop}
                style={{ display: showScroll ? 'flex' : 'none' }}
            />
        </div>
    );
};

export default TopToScroll;